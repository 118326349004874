/* header's style */
@import url("./common/index.less");

.header {
  height: @header-height;
  background-color: @pure-green;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 8vh;
  padding-right: 3vw;
  white-space: nowrap;
  overflow: hidden;
  border-bottom: @header-border-bottom solid rgba(255, 155, 0, 0.2);
  user-select: none;
  .menu {
    width: 100%;
    font-size: 50px;
    display: flex;
    overflow: hidden;
    a {
      color: black;
      background-color: @pure-yellow;
      padding: 10px 15px 10px 15px;
      margin-left: 3vw;
      border-radius: 7.5px;
    }
    .active {
      border-bottom: 7.5px solid black;
    }
  }
  .avatar {
    height: 7vh;
    width: 7vh;
    border-radius: 50%;
    background-color: rgba(225, 225, 225, 1);
    border: 2px solid orangered;
    img {
      height: 7vh;
      width: 7vh;
      object-fit: cover;
      border-radius: 50%;
      margin: 0 auto;
    }
    & div {
      height: 7vh;
      width: 7vh;
      font-size: 1rem;
      text-align: center;
      line-height: 7vh;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
