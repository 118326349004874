.container {
  position: relative;
  :global {
    .swiper-button-prev,
    .swiper-button-next {
      color: rgb(224, 131, 55);
      width: 100px;
      height: 70px;
      border-radius: 20px;
      background-color: rgba(0, 0, 0, 0.5);
      &::after {
        font-size: 60px;
      }
    }

    .swiper-pagination-bullet {
      width: 200px;
      height: 20px;
      border-radius: 6px;
      position: relative;
      bottom: 50px;
      background-color: rgb(224, 131, 55);
    }
  }
}
