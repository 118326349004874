@import url("../../user-info/UserInfo/common.less");

@wrapper-h: 55vh;
@wrapper-w: 35rem;

.wrapper {
  width: @wrapper-w;
  height: @wrapper-h;
  background-color: green;
  position: absolute;
  top: calc(50vh - (@wrapper-h / 2));
  left: calc(50vw - (@wrapper-w / 2));
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: #7aab45;

  div:nth-child(1) {
    margin: 0 auto;
    margin-bottom: 3rem;

    label {
      background-color: #ee9114;
      margin: 0 auto;
      display: block;
      width: 16rem;
      text-align-last: justify;
      font-size: 2rem;
      height: 3.5rem;
      color: black;
      padding: 0 3rem;
      line-height: 3.5rem;
    }
  }

  div {
    display: flex;
    font-size: 1.5rem;
    margin-top: 1rem;
    background-color: gray;
    border-radius: 20px;

    label {
      display: block;
      width: 10rem;
      text-align: center;
      background-color: @bg;
      margin-right: 2rem;
      text-align-last: justify;
      padding: 0.5rem 1rem;
      box-sizing: border-box;
      border-radius: 10px;
      font-weight: 600;
      color: rgb(57, 53, 53);
      user-select: none;
    }

    input {
      width: 16rem;
      border-radius: 10px;
      font-size: 1.5rem;
      padding: 0 1rem;
      border: none;
    }
  }
  .btns {
    margin-top: 3rem;
    position: relative;
    display: flex;
    justify-content: right;
    background-color: rgba(0, 0, 0, 0);
    button {
      width: 6rem;
      height: 3rem;
      border-radius: 10px;
      background-color: #ee9114;
      font-size: 2rem;
      margin: 0 2rem;
      border: none;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .open {
    display: flex;
    align-items: center;
    justify-content: left;
    background-color: rgba(0, 0, 0, 0);
    input {
      height: 1.5rem;
      width: 1.5rem;
      margin-right: 16.5rem;
      border: 0;
    }
  }
}
