@import url("../index.module.less");

@margin-top: 20px;

.wrapper {
  height: calc(@table-area-height + @pagination-height);
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  overflow: hidden scroll;
  display: block;

  .banner {
    width: 100%;
    height: 20px;
    background-color: wheat;
    margin: 0 auto;
    position: sticky;
    top: 0;
  }

  .table-content {
    margin-bottom: 20px;
    table {
      width: 98%;
      margin: 0 auto;
      border-collapse: collapse; // merge two borders into one.

      .common();

      // set cell border, no-wrap within a cell, text-overflow within a cell.
      th,
      td {
        border: 1px solid black;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 20rem;
      }

      thead {
        color: white;
        background-color: #5070c4;
        font-size: 2rem;
        height: 4rem;
        line-height: 4rem;
        position: sticky;
        top: 19px;

        td {
          padding: 0 2rem 0 2rem;
        }
        // Header: 发布信息
        .comment {
          max-width: 20vw;
        }
      }

      tbody {
        background-color: #e9ebf5;
        .common();
        tr:nth-child(odd) {
          background: #d0d5ea;
        }

        td {
          text-align: center;
          padding: 0.5rem;
          height: 3rem;
          font-size: 1.5rem;
          line-height: 3rem;
        }

        .comment {
          text-align: left;
        }
      }
    }
  }

  .pagination {
    position: sticky;
    bottom: 0;
    background-color: wheat;
    border-top: 1px solid gray;
    width: 100%;
    height: @pagination-height;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: baseline;
    font-size: 1.5rem;

    span {
      margin: 0 1.5rem;
    }

    button {
      height: 3rem;
      width: 6rem;
      font-size: 1.5rem;
      color: black;
      background-color: #ee9114;
      border-radius: 10px;
      border-color: white;
      font-weight: bold;
      &:disabled {
        background-color: gray;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.common() {
  .order {
    max-width: 4rem;
  }
  .entry {
    max-width: 20rem;
  }
  .comment {
    max-width: 20rem;
  }
  .processor {
    max-width: 10rem;
  }
  .createdAt {
    max-width: 10rem;
  }
  .validateAt {
    max-width: 10rem;
  }
  .env {
    max-width: 4rem;
    & > .content {
      background-color: #ee9114;
      padding: 0.5rem 0.75rem;
      border-radius: 10px;
    }
  }
}
