@import "./common.less";

.container {
  width: 13vw;
  height: 50vh;
  background-color: @pure-blue;
  position: absolute;
  right: 5vh;
  top: 10vh;
  border: 3px solid rgba(255, 69, 0, 0.6);
  border-radius: 10px;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  button:hover {
    cursor: pointer;
    background-color: darken(@bg, 25%);
  }
}
