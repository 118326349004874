@width: 20vw;
@height: 20vh;

.wrapper {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  font-size: 1.5rem;
  width: @width;
  height: @height;
  border-radius: 15px;
  left: 0;
  top: 0;
  padding: calc((100vh - @height) / 2) calc((100vw - @width) / 2);
  box-sizing: content-box;
  z-index: 999;

  .container {
    background-color: wheat;
    width: @width;
    height: @height;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .header {
      width: 100%;
      height: 20%;
      text-align: center;
      font-size: 2rem;
      color: red;
    }

    .detail {
      width: 80%;
      height: 60%;
      color: black;
      border: 1px solid black;
      border-radius: 10px;
      padding: 20px;
      box-sizing: border-box;
    }

    .btns {
      display: flex;
      justify-content: right;
      width: 80%;
      button {
        width: 5rem;
        font-size: 1.25rem;
        border-radius: 10px;
        border: none;
        background-color: black;
        color: wheat;
        margin-left: 10px;
      }
    }
  }
}
