@import url("../../../layout/common");

@border-color: gray;
@space-height: calc(@main-content-height - 10vh);
@menu-content-height: calc(@space-height - 3px - 100px);
// border width is 6px totally, menu area width is 15vw.
@menu-content-width: calc(85vw - 6px - 100px);

.wrapper {
  width: 100vw;
  height: @main-content-height;
  background-color: @pure-yellow;

  .header-info,
  .main-content {
    margin: 0 auto;
    width: 100vw;
  }

  .header-info {
    height: 10vh;
    border: 3px solid @border-color;
    box-sizing: border-box;
  }

  .main-content {
    display: flex;
    margin: 0 auto;
    .menu,
    .menu-content-wrapper {
      height: calc(@main-content-height - 10vh);
      border: 3px solid @border-color;
      border-top: 0;
      box-sizing: border-box;
    }

    .menu {
      width: 15vw;
      border-right: 0;
    }

    .menu-content-wrapper {
      width: 85vw;
      .content {
        height: @menu-content-height;
        width: @menu-content-width;
        margin: 0 auto;
        margin-top: 50px;
        border-radius: 20px;
        background-color: wheat;
        border: 1px solid gray;
        padding: 20px 0 20px 0;
      }
    }
  }
}
