@import url("../layout/common/index.less");

@height: calc(@main-content-height / 2);
@width: calc(100vw / 2);
@width-left: calc(@width / 2.2);
@width-right: calc(@width - @width-left);

.wrapper {
  height: @main-content-height;
  background-color: #5070c4;
  user-select: none;

  .container {
    height: @height;
    width: @width;
    width: calc(main);
    margin: 0 auto;
    position: relative;
    top: calc((@main-content-height - @height) / 2);
    display: flex;

    .left,
    .right {
      height: 100%;
    }
    .left {
      width: @width-left;
      background-color: @pure-green * 1.1;
      border-radius: 20px 0 0 20px;
      text-align: center;
      line-height: @height;
      font-size: 2rem;
    }
    .right {
      width: @width-right;
      background-color: @pure-green * 1.3;
      border-radius: 0 20px 20px 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .common() {
        display: flex;
        align-items: center;
        font-size: 1.5rem;
        margin-top: 0.75rem;
        width: 23rem;
      }

      .switch {
        position: absolute;
        top: 0;
        right: 0;
        height: 3rem;
        font-size: 1.5rem;
        background-color: @pure-yellow;
        width: 8rem;
        text-align: center;
        line-height: 3rem;
        border-radius: 0 20px 0 20px;
        &:hover {
          cursor: pointer;
          background-color: lighten(@pure-yellow, 20%);
        }
      }

      .username,
      .password {
        .common();
        justify-content: left;

        label {
          display: block;
          width: 5rem;
          height: 2rem;
          line-height: 2rem;
          text-align-last: justify;
          padding: 0 1rem 0 1rem;
          margin-right: 0.5rem;
          border-radius: 0.5rem;
          background-color: @pure-yellow;
        }

        input {
          width: 12rem;
          height: 2.25rem;
          border: 0;
          border-radius: 0.5rem;
          font-size: 1.1rem;
          padding-left: 0.5rem;
        }

        img {
          width: 1.5rem;
          height: 1.5rem;
          margin-left: 0.5rem;
          &:hover {
            cursor: pointer;
          }
        }
      }

      .submit {
        .common();
        justify-content: center;
        margin-top: 2rem;
        width: 20rem;
        padding-right: 2rem;

        .remember {
          display: flex;
          align-items: center;
          width: 7rem;
          margin-right: 2rem;
          padding: 0 0.5rem 0 0.5rem;
          border-radius: 0.5rem;
          background-color: @pure-yellow;
          justify-content: space-between;

          label {
            width: 6rem;
            text-align-last: justify;
            font-size: 1.2rem;
          }
          input {
            height: 1.5rem;
            width: 1.5rem;
            border: 0;
          }
        }

        button {
          border: 0;
          height: 2rem;
          width: 5rem;
          font-size: 1.3rem;
          padding: 0 1rem 0 1rem;
          border-radius: 0.5rem;
          background-color: @pure-yellow;
          text-align-last: justify;
          &:hover {
            background-color: lighten(@pure-yellow, 20%);
            cursor: pointer;
          }
        }
      }
    }
  }
}
