@import "../UserInfo/common.less";

.container {
  display: flex;
  height: 20.5vh;
  width: 94%;
  background-color: #6673ae;
  flex-direction: column;
  justify-content: space-evenly;

  .header {
    height: @item-height;
    line-height: @item-height;
    border-radius: (@item-height / 10);
    background-color: @bg;
    width: 50%;
    margin-left: @ml;
    text-align: center;
    font-size: 1.5rem;
  }

  .nick,
  .status,
  .role {
    height: @item-height;
    line-height: @item-height;
    display: flex;
    justify-content: space-between;
    margin: 0 @ml 0 @ml;
    font-size: 1.1rem;

    label,
    div {
      background-color: @bg;
      border-radius: (@item-height / 10);
    }

    label {
      width: 30%;
      text-align: center;
    }
    div {
      width: 62%;
      padding-left: 1vh;
    }
  }
}
