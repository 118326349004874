.wrapper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .base-info {
    width: 80%;
    display: flex;
    justify-content: center;

    div {
      margin: 0 3rem 0 3rem;
    }
  }

  .base-info > div,
  .url {
    font-size: 2rem;
    border: 1px solid gray;
    padding: 5px 1rem;
    height: 3rem;
    line-height: 3rem;
    border-radius: 10px;
    background-color: rgba(255, 94, 0, 0.6);
    color: rgb(50, 45, 45);
  }

  .url {
    cursor: pointer;
    margin-right: 10%;
  }
}
