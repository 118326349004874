@import url("../layout/common/index.less");

.container {
  height: @main-content-height;
  background-color: #5070c4;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  > div {
    height: calc(@main-content-height - 17px);
    background-color: rgba(246, 197, 13, 1);
  }

  .left {
    width: 74vw;
  }
  .right {
    width: 25vw;
  }
}
