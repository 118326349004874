@import "../UserInfo/common.less";

.container {
  display: flex;
  height: 20.5vh;
  width: 94%;
  background-color: #6673ae;
  flex-direction: column;

  .header {
    height: @item-height;
    line-height: @item-height;
    border-radius: (@item-height / 10);
    background-color: @bg;
    width: 50%;
    margin: @ml*2 0 @ml*2 @ml;
    text-align: center;
    font-size: 1.5rem;
  }

  .btns {
    display: flex;
    flex-wrap: wrap;

    button {
      height: @item-height;
      line-height: @item-height;
      border-radius: (@item-height / 10);
      background-color: @bg;
      width: 35%;
      margin: @ml 0 0 @ml;
      text-align: center;
      font-size: 1.1rem;
      border: none;
    }
  }
}
