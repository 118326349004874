@import "../UserInfo/common.less";

.container {
  display: flex;
  height: 6.5vh;
  width: 94%;
  background-color: #6673ae;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  button {
    height: @item-height;
    line-height: @item-height;
    border-radius: (@item-height / 10);
    background-color: @bg;
    width: 30%;
    font-size: 1.1rem;
    border: none;
  }
}
