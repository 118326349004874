@wrapper-height: 30vh;
@wrapper-width: 30rem;
.wrapper {
  height: @wrapper-height;
  width: @wrapper-width;
  background-color: rgb(93, 145, 93);
  position: fixed;
  top: calc(50vh - (@wrapper-height / 2));
  left: calc(50vw - (@wrapper-width / 2));
  border-radius: 15px;
  z-index: 99;
  padding: 2rem 2rem 2rem 2rem;

  .label() {
    label {
      display: block;
      font-size: 1.5rem;
      margin-right: 2rem;
      width: 8rem;
      height: 3rem;
      line-height: 3rem;
      border-radius: 10px;
      background-color: white;
      text-align: center;
      user-select: none;
      &:hover {
        cursor: not-allowed;
      }
    }
  }

  .file-uploader {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .label();

    .file-info {
      width: 20rem;
      height: 8rem;
      font-size: 1.25rem;
      display: flex;
      flex-direction: column;

      .file-upload-btn {
        font-size: 1.25rem;
        width: 5rem;
        height: 2rem;
        border-radius: 5px;
        text-align: center;
        border: none;
        margin-bottom: 1rem;
        &:hover {
          cursor: pointer;
        }
      }

      & > div {
        height: 6rem;
        border-radius: 5px;
        overflow: hidden scroll;
        word-wrap: break-word;
        background-color: rgba(255, 255, 255, 0.75);
        padding: 5px;
        & > p {
          font-size: 1rem;
          margin: 0;
          color: gray;
          & > span {
            font-weight: bold;
          }
        }
      }
    }
  }

  .deploy-comment {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    .label();

    textarea {
      font-size: 1.25rem;
      width: 20rem;
      height: 8rem;
      border-radius: 5px;
      padding: 5px;
      resize: none;
    }
  }

  .btns {
    margin-top: 3rem;
    position: relative;
    bottom: 0rem;
    left: 5rem;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    button {
      width: 5rem;
      height: 2.5rem;
      border-radius: 10px;
      background-color: #e4a24c;
      font-size: 1.5rem;
      margin: 0 2rem;
      border: none;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
