@import url("../../../AppWorkbench/index.module.less");

@action-area-height: 10%;
@table-area-height: 80%;
@pagination-height: 10%;

.wrapper {
  height: inherit;
  width: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  background-color: inherit;
}
