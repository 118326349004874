@import url("./common/index.less");
@import url("../layout/common/index.less");

.container {
  background-color: @pure-blue;
  height: @main-content-height;
  box-sizing: border-box;
  padding: 0 50px 0 50px;
  overflow: hidden;
  user-select: none;

  .editor {
    height: 5vh;
    line-height: 5vh;
    background-color: lighten(@pure-yellow, 10%);
    margin: 0 auto;
    margin-top: 30px;
    width: 75%;
    border-radius: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    padding-left: 1rem;

    button {
      height: 2.5rem;
      width: 5rem;
      background-color: #ee9114;
      border-radius: 10px;
      font-size: 1.5rem;
      margin: 0 1rem;
      color: black;
      border-color: rgba(155, 155, 155, 1);
      &:hover {
        cursor: pointer;
      }
    }
  }

  .cards {
    width: calc(15vw * 5 + 50px * 4);
    display: grid;
    justify-items: center;
    margin: 0 auto;
    margin-top: 30px;
    grid-column-gap: 50px;
    justify-content: left;
    grid-template-columns: repeat(auto-fit, minmax(15vw, max-content));
  }

  .pagination {
    width: 30rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 30px;

    button {
      height: 2.5rem;
      width: 6rem;
      font-size: 1.5rem;
      line-height: 2.5rem;
      text-align: center;
      border-radius: 5px;
      margin: 1rem;
      background-color: #ee9114;

      &:nth-child(2) {
        border: none;
        font-size: 1rem;
      }
    }
  }
}
