@import url("../../../../layout/common/index.less");

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    width: 40%;
    height: 5rem;
    line-height: 5rem;
    font-size: 3rem;
    border: 1px solid gray;
    border-radius: 1rem;
    margin-top: 3rem;
    text-align: center;
    text-align-last: justify;
    padding: 10px 3rem;
    &:hover {
      cursor: pointer;
    }
  }

  .active {
    background-color: lighten(@pure-green, 5%);
  }
}
