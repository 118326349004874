.container {
  height: calc(90vh - 20px);
  width: 74vw;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
