.container {
  height: 25vh;
  width: 15vw;
  margin: 30px 0;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  @border-radius: 15px;

  a {
    width: inherit;
    height: inherit;
    display: block;
  }

  img {
    width: inherit;
    height: inherit;
    object-fit: fill;
    border-radius: @border-radius @border-radius 0 0;
    &:hover {
      cursor: pointer;
    }
  }

  .zhName {
    background-color: rgba(122, 122, 122);
    color: white;
    height: 3rem;
    width: inherit;
    font-size: 1.5rem;
    line-height: 3rem;
    padding-left: 1.5rem;
    box-sizing: border-box;
    border-radius: 0 0 @border-radius @border-radius;
    user-select: text;

    display: flex;
    align-items: center;
    justify-content: space-between;

    & > img {
      height: 2rem;
      width: 2rem;
      margin-right: 2rem;
    }
  }
}
