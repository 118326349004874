@import url("../index.module.less");

.wrapper {
  height: @action-area-height;
  border-bottom: 1px solid gray;
  border-radius: 20px 20px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  box-sizing: border-box;

  & > button {
    font-size: 2rem;
    width: 8rem;
    height: 4rem;
    margin-left: 1vw;
    border-radius: 10px;
    border-color: rgba(128, 128, 128, 0.5);
    position: relative;
    top: -10%;
    &:hover {
      cursor: pointer;
    }
  }
}
