body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*去除a标签下划线*/
a {
  text-decoration: none;
}
/*去除未被访问的a标签的下划线*/
a:link {
  text-decoration: none;
}
/*去除已经被访问过的a标签的下划线*/
a:visited {
  text-decoration: none;
}
/*去除鼠标悬停时的a标签的下划线*/
a:hover {
  text-decoration: none;
}
/*去除正在点击的a标签的下划线（鼠标按下，尚未松开）*/
a:active {
  text-decoration: none;
}
/*去除获得焦点的a标签的下划线（被鼠标点击过）*/
a:focus {
  text-decoration: none;
}

/* tooltip */
.rc-tooltip-content :hover {
  cursor: pointer;
}

/* scrollbar width */
::-webkit-scrollbar {
  width: 6px;
  display: none;
}

/* scrollbar Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 6px;
}

/* scrollbar Handle */
::-webkit-scrollbar-thumb {
  background: #dddddd;
  border-radius: 5px;
}

/* scrollbar Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #777777;
}
